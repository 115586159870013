import axios from 'axios'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Card from '../../components/Card'
import { PermissionData } from '../../layouts/dashboard/default'

const AverageHourList = ({ api, user_data, showToast }) => {

    const Permissions = useContext(PermissionData);
    const themeData = useSelector((state) => state.mode.colorcustomizer);
    const [loading, setLoading] = useState(false);
    const [totalColor, setTotalColor] = useState('');
    const [workingHourData, setWorkingHourData] = useState([]);
    const [breakHourData, setBreakHourData] = useState([]);
    const [totalWrokingHour, setTotalWrokingHour] = useState('');
    const [totalBreakHour, setTotalBreakHour] = useState('');
    const [userId, setUserId] = useState('');
    const [userDetail, setUserDetail] = useState('');
    const [date, setDate] = useState('');
    const [month, setMonth] = useState(moment().month() + 1);
    const [year, setYear] = useState(moment().year());
    const [cancelToken, setCancelToken] = useState(null);
    const [show, setShow] = useState(false);
    const [deleteid, setDeleteid] = useState('');
    const [userList, setUserList] = useState([]);
    const [average, setAverage] = useState(0);
    const [error, setError] = useState('');
    const [showEditButton, setShowEditButton] = useState(null);
    const [showInput, setShowInput] = useState(null);
    const [empUserIds, setEmpUserIds] = useState([]);

    useEffect(() => {
        if (themeData === 'theme-color-default') {
            setTotalColor('#2e46ba');
        }
        else if (themeData === 'theme-color-blue') {
            setTotalColor('#009cc7');
        }
        else if (themeData === 'theme-color-gray') {
            setTotalColor('#91969e');
        }
        else if (themeData === 'theme-color-red') {
            setTotalColor('#af424f');
        }
        else if (themeData === 'theme-color-yellow') {
            setTotalColor('#bb550e');
        }
        else if (themeData === 'theme-color-pink') {
            setTotalColor('#b76b8f');
        }
        return () => { };
    }, [themeData]);


    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        const maxDate = moment().subtract(1, 'day').toISOString().split('T')[0];

        if (selectedDate > maxDate) {
            setError('Future dates are not allowed!');
            setDate(maxDate); // Clear the invalid date
        } else {
            setError('');
            setDate(selectedDate);
        }
    };

    const WorkingHourListing = async ({ id, month, year }) => {
        try {
            setLoading(true);
            if (cancelToken) {
                cancelToken.cancel('New request initiated');
            }
            const source = axios.CancelToken.source();
            setCancelToken(source);
            if (!id) return;
            const response = await api.get(`/averageHours/${id}?month=${month}&year=${year}`);
            if (response.data !== undefined) {
                setAverage(response.data)
            }
        } catch (error) {
            setAverage({})
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        api.get('/punchEmployee')
            .then(res => setEmpUserIds(res.data.map(item => (
                {
                    emp_code: item.emp_code,
                    _id: item._id,
                    firstname: item.firstname,
                    last_name: item.last_name
                }
            ))))
            .catch((err) => setEmpUserIds([user_data]))
        setUserId(user_data._id)
    }, []);

    useEffect(() => {
        WorkingHourListing({ year, month, id: userId });
    }, [year, month, userId]);

    const handleClose = () => {
        setShow(false);
    }

    function deletemodel(id) {
        setDeleteid(id);
        setShow(true);
    }
    const formatTime = (time) => (moment(time).isValid() ? moment(time).format('hh:mm A') : '-');
    const formatTime24H = (time) => (moment(time).isValid() ? moment(time).format('HH:mm') : '-');

    const updatePunchingHours = async (id, hours) => {
        try {
            const dateTimeString = `${date} ${hours}`
            const dateTime = moment(dateTimeString, 'YYYY-MM-DD HH:mm').format()
            await api.put(`/updatePunches/${id}`, { punch_time: dateTime })
            showToast('Hour updated', 'success')
            WorkingHourListing({ date, id: userId })
            setShowInput(null)
        } catch (error) {
            showToast(error.message, 'error')
        } finally {
            setShowInput(null)
        }
    }

    const calculateDuration = (checkIn, checkOut) => {
        if (!moment(checkIn).isValid() || !moment(checkOut).isValid()) return '-';

        const hours = moment(checkOut).diff(moment(checkIn), 'hours');
        const minutes = moment(checkOut).diff(moment(checkIn), 'minutes') % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    };

    return (
        <>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Average hour</h4>
                            </div>
                        </Card.Header>

                        <Card.Header className={`d-flex justify-content-between`}>
                            <div className="row align-items-center w-100">
                                <Col sm="12" lg="3">
                                    <Form.Group className="form-group">
                                        <Form.Label>Month</Form.Label>
                                        <select
                                            name="user-list-table_length"
                                            aria-controls="user-list-table"
                                            className="form-select"
                                            defaultValue={month}
                                            onChange={(e) => setMonth(e.target.value)}
                                        >
                                            <option value="">All</option>
                                            <option value="4">April</option>
                                            <option value="5">May</option>
                                            <option value="6">June</option>
                                            <option value="7">July</option>
                                            <option value="8">August</option>
                                            <option value="9">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                            <option value="1">January</option>
                                            <option value="2">February</option>
                                            <option value="3">March</option>
                                        </select>
                                    </Form.Group>
                                </Col>
                                <Col sm="12" lg="3">
                                    <Form.Group className="form-group">
                                        <Form.Label>Year</Form.Label>
                                        <select
                                            name="user-list-table_length"
                                            aria-controls="user-list-table"
                                            className="form-select"
                                            defaultValue={year}
                                            onChange={(e) => setYear(e.target.value)}
                                        >
                                            <option
                                                value={moment().year() - 2}
                                            >
                                                {moment().year() - 2}
                                            </option>
                                            <option
                                                value={moment().year() - 1}
                                            >
                                                {moment().year() - 1}
                                            </option>
                                            <option
                                                value={moment().year()}
                                            >
                                                {moment().year()}
                                            </option>
                                        </select>
                                    </Form.Group>
                                </Col>
                                {(Permissions.includes("View Punching")) && (
                                    <Col sm="12" lg="3">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="permission_description">Employee</Form.Label>
                                            <select
                                                name="user-list-table_length"
                                                aria-controls="user-list-table"
                                                className="form-select form-select"
                                                value={userId}
                                                onChange={(e) => setUserId(e.target.value)} >
                                                {empUserIds && empUserIds.map((item) => (
                                                    <option value={item._id} key={item._id}>
                                                        {item.firstname + ' ' + item.last_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                )}
                                {/* Average remaining for employee */}

                                {/* <Col sm="12" lg="3" className='h-100'>
                                    <Form.Label>Employee average </Form.Label>
                                    <h4 className='mt-2'>{average}</h4>
                                </Col> */}
                            </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <div className="table-responsive">
                                <table id="user-list-table" className="table table-striped" role="grid" data-toggle="data-table">
                                    <thead>
                                        <tr className="light">
                                            <th>
                                                <span className="title">Days</span>
                                            </th>
                                            <th>
                                                <span className="title">Leaves</span>
                                            </th>
                                            <th>
                                                <span className="title">Average</span>
                                            </th>

                                        </tr>
                                    </thead>
                                    {loading ?
                                        <tbody>
                                            <tr>
                                                <td colSpan={20} className="text-center">
                                                    <Spinner
                                                        animation="border"
                                                        variant="primary"
                                                        role="status"
                                                    >
                                                        <span className="visually-hidden">
                                                            Loading...
                                                        </span>
                                                    </Spinner>
                                                </td>
                                            </tr>
                                        </tbody>
                                        :
                                        <tbody>
                                            {Object.keys(average).length == 0 ?
                                                <tr>
                                                    <td colSpan={99} className='text-center'>
                                                        No Data Found
                                                    </td>
                                                </tr> :
                                                <tr className="border-light">
                                                    <td>
                                                        {(average.totalPresentDays && average.totalWorkingDays) && `${average.totalPresentDays} / ${average.totalWorkingDays}`}
                                                    </td>
                                                    <td>
                                                        {average.leaveHistory}
                                                    </td>
                                                    <td>
                                                        {average.totalAverage}
                                                    </td>
                                                </tr>

                                            }
                                        </tbody>}
                                </table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default AverageHourList;