import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import { PermissionData } from "../../layouts/dashboard/default";
import ToastContainerWrapper from "../../plugins/ToastContainerWrapper";
import { showToast } from "../../plugins/toastUtils";

const TaskList = ({ api, user_data }) => {
  const Permissions = useContext(PermissionData);
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [userList, setUserList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [status, setStatus] = useState("");
  const [project_id, setProject] = useState("");
  const [userId, setUser] = useState("");
  const [show, setShow] = useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [taskStatus, setTaskStatus] = useState("");
  const [taskshow, setTaskshow] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [totalPages, setTotalPages] = useState(1);
  const [entry] = useState(10);
  const [search, setSearch] = useState(false);
  const [totalData, setTotalData] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [dataperPage, setDataperPage] = useState(entry);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  let lastTaskData = tasks.slice(-1);

  const Taskdata = async () => {
    setLoading(true);
    api
      .get(
        `/tasks?search=${searchValue}&page=${page}&userId=${userId}&projectId=${project_id}&limit=${dataperPage}&nameSort=${order}&status=${status}`
      )
      .then((response) => {
        if (response.data !== undefined) {
          if (response.data.status === false) {
            navigate("/403");
          }
          setTasks(response.data.taskData);
          setProjectList(response.data.projectData);
          setUserList(response.data.userData);
          setTotalPages(response.data.totalPages);
          setTotalData(response.data.totalData);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status == 403) {
          navigate("/403");
        }
        showToast(error.response.data.errors, "error");
      });
  };

  const Handledelete = async () => {
    const id = deleteid;
    setLoading(true);
    if (id !== undefined) {
      await api
        .post(`/deleteTask/${id}`, "")
        .then((response) => {
          showToast(response.data.message, "success");
          setShow(false);
          Taskdata();
        })
        .catch((error) => {
          if (error.response.status == 403) {
            navigate("/403");
          }
          showToast(error.response.data.errors, "error");
          setLoading(false);
        });
    }
    setLoading(false);
  };

  const TaskStatusUpadte = async () => {
    const id = taskStatus;
    if (id !== undefined) {
      await api
        .post(`/task_status_update/${id}`, "")
        .then((response) => {
          setTimeout(() => {
            showToast(response.data.message, "success");
          }, 300);
          setTaskshow(false);
          Taskdata();
        })
        .catch((error) => {
          showToast(error.response.data.errors, "error");
        });
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  function deletemodel(id) {
    setDeleteid(id);
    setShow(true);
  }

  const PageChange = ({ selected }) => {
    setPage(selected + 1);
  };

  const CompleteTaskModel = (id) => {
    setTaskStatus(id);
    setTaskshow(true);
  };

  const setPerPage = (value) => {
    setDataperPage(value);
    setPage(1);
  };

  const sorting = () => {
    if (order === "ASC") {
      setOrder("DSC");
    }
    if (order === "DSC") {
      setOrder("ASC");
    }
  };

  const handleSearch = async (event) => {
    if (searchValue && event.key === "Enter") {
      setPage(1);
      setSearch(true);
      Taskdata();
    }
  };

  const TaskhandleClose = () => {
    setTaskshow(false);
  };

  useEffect(() => {
    Taskdata();
  }, [status, userId, project_id, page, dataperPage, order]);

  useEffect(() => {
    if (!searchValue && search) {
      Taskdata();
    }
    return () => { };
  }, [searchValue]);

  return (
    <>
      <Row>
        <Modal show={show} onHide={handleClose} className="text-center">
          <Modal.Header closeButton>
            <Modal.Title style={{ marginLeft: "5rem" }}>
              Do you want to delete it ?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              className="me-2"
              variant="primary"
              disabled={loading}
              onClick={() => Handledelete()}
            >
              {loading ? "Deleting..." : "Delete"}
            </Button>
            <Button
              variant="danger"
              onClick={() => handleClose()}
              value="Cancel"
            >
              {" "}
              Cancel
            </Button>
          </Modal.Body>
        </Modal>
        <Modal show={taskshow} onHide={TaskhandleClose} className="text-center">
          <Modal.Header closeButton>
            <Modal.Title style={{ marginLeft: "5rem" }}>
              Is this task is completed ?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button variant="primary me-2" onClick={() => TaskStatusUpadte()}>
              yes
            </Button>
            <Button
              variant="danger"
              onClick={() => TaskhandleClose()}
              value="Cancel"
            >
              Cancel
            </Button>
          </Modal.Body>
        </Modal>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Tasks</h4>
              </div>
              {Permissions.includes("Add Task") && (
                <div className="Add-task">
                  <Link to="add">
                    <h4 className="btn btn-primary">
                      <i
                        className="fa-solid fa-plus"
                        style={{ marginRight: "5px" }}
                      ></i>
                      New Task
                    </h4>
                  </Link>
                </div>
              )}
            </Card.Header>
            <Card.Header className={`d-flex justify-content-between`}>
              <div className="row align-items-center w-100">
                <div className="col-md-2">
                  <div
                    className="dataTables_length d-flex align-items-center"
                    id="user-list-table_length"
                    style={{ gap: "2%" }}
                  >
                    <label>Show</label>
                    <select
                      name="user-list-table_length"
                      aria-controls="user-list-table"
                      className="form-select form-select-sm w-100"
                      onChange={(e) => setPerPage(e.target.value)}
                      defaultValue={entry}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div
                    className="dataTables_length d-flex align-items-center"
                    id="user-list-table_length"
                    style={{ gap: "2%" }}
                  >
                    <label>Status</label>
                    <select
                      name="user-list-table_length"
                      aria-controls="user-list-table"
                      className="form-select form-select-sm w-100"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="0">Pending</option>
                      <option value="1">Completed</option>
                    </select>
                  </div>
                </div>
                {user_data.roleData[0].role_name === "Admin" && (
                  <>
                    <div className="col-md-2">
                      <div
                        className="dataTables_length d-flex align-items-center"
                        id="user-list-table_length"
                        style={{ gap: "2%" }}
                      >
                        <label>Employee</label>
                        <select
                          name="user-list-table_length"
                          aria-controls="user-list-table"
                          className="form-select form-select-sm w-75"
                          onChange={(e) => setUser(e.target.value)}
                        >
                          <option value="">All</option>
                          {userList &&
                            userList.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.firstname + " " + item.last_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </>
                )}
                <div className="col-md-2">
                  <div
                    className="dataTables_length d-flex align-items-center"
                    id="user-list-table_length"
                    style={{ gap: "2%" }}
                  >
                    <label>Projects</label>
                    <select
                      name="user-list-table_length"
                      aria-controls="user-list-table"
                      className="form-select form-select-sm w-75"
                      onChange={(e) => setProject(e.target.value)}
                      defaultValue={project_id}
                    >
                      <option value="">All</option>
                      {projectList &&
                        projectList.map((item, index) => (
                          <option value={item._id} key={index}>
                            {item.title}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className={`col-md-4 ${user_data.roleData[0].role_name !== "Admin" ? 'offset-md-2' : ''}`}>
                  <div
                    id="user-list-table_filter"
                    className="dataTables_filter d-flex align-items-center justify-content-end"
                    style={{ gap: "2%" }}
                  >
                    <label>Search:</label>
                    <input
                      type="search"
                      className="form-control form-control-sm w-50"
                      placeholder=""
                      aria-controls="user-list-table"
                      onChange={(event) => setSearchValue(event.target.value)}
                      onKeyDown={handleSearch}
                    />
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="user-list-table"
                  className="table table-striped sticky-header-table-last"
                  role="grid"
                  data-toggle="data-table"
                >
                  <thead>
                    <tr className="ligth">
                      <th>No</th>
                      <th style={{ minWidth: "300px", whiteSpace: "pre-wrap" }}>
                        Project Name
                      </th>
                      <th>Employee</th>
                      <th
                        style={{ cursor: "pointer" }}
                        onClick={() => sorting("title")}
                      >
                        Title
                      </th>
                      <th>Description</th>
                      <th>Estimated Hours</th>
                      <th>Actual Hours</th>
                      <th>Factor</th>
                      <th min-width="100px">Status</th>
                      {(Permissions.includes("Delete Task") ||
                        Permissions.includes("Update Task")) && (
                          <th min-width="100px">Action</th>
                        )}
                    </tr>
                  </thead>
                  {loading ? (
                    <tbody>
                      <tr>
                        <td colSpan={20} className="text-center">
                          <Spinner
                            animation="border"
                            variant="primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody className="text-capitalize">
                      {tasks && tasks?.length > 0 ? (
                        tasks.map((item, index) => {
                          const factorRound =
                            Math.round(
                              (item.totalHours / item.estimatedHours) * 10
                            ) / 10;
                          return (
                            <tr key={index} className="border-light">
                              <td>{item.index}</td>
                              <td
                                style={{
                                  minWidth: "300px",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {item.projectData &&
                                  item.projectData.map((data, index) => (
                                    <span key={index}>{data.title}</span>
                                  ))}
                              </td>
                              <td>
                                <div className="iq-media-group iq-media-group-1">
                                  {item.userData &&
                                    item.userData.map((data, index) => {
                                      return (
                                        <Link
                                          to="#"
                                          className="iq-media-1"
                                          key={index}
                                        >
                                          <div
                                            className="icon iq-icon-box-3 rounded-pill d-flex justify-content-center align-items-center"
                                            style={{
                                              textTransform: "uppercase",
                                            }}
                                            title={
                                              data.firstname +
                                              " " +
                                              data.last_name
                                            }
                                            key={index}
                                          >
                                            {data.firstname &&
                                              data.firstname.charAt(0)}
                                            {data.last_name &&
                                              data.last_name.charAt(0)}
                                          </div>
                                        </Link>
                                      );
                                    })}
                                </div>
                              </td>
                              <td>
                                <span
                                  className={`text-${item.task_type === "1" && "danger"
                                    }`}
                                >
                                  {item.task_type == 0
                                    ? "Feature "
                                    : item?.task_type == 1 && "Bug "}{" "}
                                </span>{" "}
                                {item?.task_type && "-"} {item.title}
                              </td>
                              <td>
                                <span
                                  title={
                                    item.short_description &&
                                      item.short_description?.length > 30
                                      ? item.short_description
                                      : null
                                  }
                                >
                                  {item.short_description &&
                                    item.short_description?.length > 30
                                    ? item.short_description.slice(0, 30) +
                                    "..."
                                    : item.short_description || "N/A"}
                                </span>
                              </td>
                              <td>
                                {item.task_type !== "1" ? (
                                  <span> {item?.task_estimation}</span>
                                ) : (
                                  <span>-</span>
                                )}
                              </td>
                              <td>
                                <span>{item.totalHours}</span>
                              </td>
                              <td>
                                {item?.task_estimation ? (
                                  item?.task_type == 0 ? (
                                    <span>{factorRound}</span>
                                  ) : item?.task_type == 1 ? (
                                    <span>-</span>
                                  ) : (
                                    <span>{factorRound}</span>
                                  )
                                ) : (
                                  <span>-</span>
                                )}
                              </td>
                              {Permissions.includes("Delete Task") ||
                                Permissions.includes("Update Task") ? (
                                <>
                                  <td>
                                    <span
                                      className={`badge bg-${item.task_status == 0
                                        ? "warning"
                                        : "success"
                                        } p-2 w-100`}
                                      style={{ fontWeight: "200" }}
                                    >
                                      {item.task_status == 0
                                        ? "Pending"
                                        : "Completed"}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="flex align-items-center list-user-action">
                                      {" "}
                                      {Permissions.includes("Update Task") && (
                                        <Link
                                          className="btn btn-sm btn-icon btn-warning"
                                          style={{ marginRight: "5px" }}
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title=""
                                          data-original-title="Edit"
                                          to={`edit/${item._id}`}
                                        >
                                          <span className="btn-inner">
                                            <svg
                                              width="20"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              ></path>
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              ></path>
                                              <path
                                                d="M15.1655 4.60254L19.7315 9.16854"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              ></path>
                                            </svg>
                                          </span>
                                        </Link>
                                      )}
                                      {Permissions.includes("Delete Task") && (
                                        <Link
                                          onClick={() => deletemodel(item._id)}
                                          className="btn btn-sm btn-icon btn-danger"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title=""
                                          data-original-title="Delete"
                                          to="#"
                                        >
                                          <span className="btn-inner">
                                            <svg
                                              width="20"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              stroke="currentColor"
                                            >
                                              <path
                                                d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              ></path>
                                              <path
                                                d="M20.708 6.23975H3.75"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              ></path>
                                              <path
                                                d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              ></path>
                                            </svg>
                                          </span>
                                        </Link>
                                      )}
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <td>
                                  <div className="flex align-items-center list-user-action">
                                    {item.task_status == 0 ? (
                                      <Link
                                        onClick={() =>
                                          CompleteTaskModel(item._id)
                                        }
                                        className="btn btn-sm btn-icon btn-warning"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Delete"
                                        to="#"
                                      >
                                        <span
                                          className="btn-inner"
                                          style={{
                                            letterSpacing: "1px",
                                            fontWeight: "300",
                                          }}
                                        >
                                          Pending... &nbsp;
                                          <svg
                                            width="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M10.309 6.90385L15.7049 11.2639C15.835 11.3682 15.8573 11.5596 15.7557 11.6929L9.35874 20.0282C8.95662 20.5431 8.36402 20.8344 7.72908 20.8452L4.23696 20.8882C4.05071 20.8903 3.88775 20.7613 3.84542 20.5764L3.05175 17.1258C2.91419 16.4915 3.05175 15.8358 3.45388 15.3306L9.88256 6.95545C9.98627 6.82108 10.1778 6.79743 10.309 6.90385Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              opacity="0.4"
                                              d="M18.1208 8.66544L17.0806 9.96401C16.9758 10.0962 16.7874 10.1177 16.6573 10.0124C15.3927 8.98901 12.1545 6.36285 11.2561 5.63509C11.1249 5.52759 11.1069 5.33625 11.2127 5.20295L12.2159 3.95706C13.126 2.78534 14.7133 2.67784 15.9938 3.69906L17.4647 4.87078C18.0679 5.34377 18.47 5.96726 18.6076 6.62299C18.7663 7.3443 18.597 8.0527 18.1208 8.66544Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                      </Link>
                                    ) : (
                                      <Link
                                        className="btn btn-sm btn-icon btn-success"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Delete"
                                        to="#"
                                      >
                                        <span className="btn-inner">
                                          Completed &nbsp;
                                          <i
                                            className="fa-regular fa-circle-check"
                                            style={{
                                              position: "relative",
                                              top: "1px",
                                            }}
                                          ></i>
                                        </span>
                                      </Link>
                                    )}
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="10" className="text-center">
                            <span className="" aria-hidden="true"></span>
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
              <div className="row mt-3 w-100">
                <div className="col-md-6">
                  <div
                    className="dataTables_length d-flex align-items-center mx-3"
                    id="user-list-table_length"
                  >
                    {tasks?.length > 0 && (
                      <label>
                        Showing {tasks[0]?.index} to {lastTaskData[0]?.index} of{" "}
                        {totalData} entries
                      </label>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  {/* <div id="user-list-table_filter" className="dataTables_filter d-flex align-items-center justify-content-end" style={{ gap: '2%', position: "relative", right: '30%' }}>
                                        {tasks?.length > 0 && (
                                            <div className="dataTables_paginate paging_simple_numbers" id="user-list-table_paginate"> */}
                  <ReactPaginate
                    nextLabel="Next"
                    onPageChange={PageChange}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={totalPages}
                    previousLabel="Previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination float-end"
                    renderOnZeroPageCount={null}
                  />

                  {/* <ReactPaginate
                                                    previousLabel={'Previous'}
                                                    nextLabel={'Next'}
                                                    pageCount={totalPages}
                                                    onPageChange={PageChange}
                                                    containerClassName={'pagination align-items-center'}
                                                    previousClassName={'paginate_button page-item previous'}
                                                    previousLinkClassName={'page-link border-0'}
                                                    nextLinkClassName={'page-link border-0'}
                                                    nextClassName={'paginate_button page-item next border-none'}
                                                    disabledClassName={'disabled'}
                                                    activeClassName={'active'}
                                                    breakLabel="..."
                                                /> */}
                  {/* </div>
                                        )}
                                    </div> */}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <ToastContainerWrapper />
      </Row>
    </>
  );
};

export default TaskList;
