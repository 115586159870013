import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";

const SalaryStructureList = ({ api }) => {

  const [salaryListing, setSalaryListing] = useState([]);
  const [order, setOrder] = useState("ASC");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const SalaryData = async () => {
    setLoading(true);
    await api.get(`/salaryStructureListing`).then((response) => {
      if (response.data.status === false) {
        navigate('/403')
      }
      setSalaryListing((response.data.salaryStructureData).reverse());
      setLoading(false);
    }).catch((error) => {
      console.log("error", error)
    });
  };

  useEffect(() => {
    SalaryData();
    return () => { };
  }, []);

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...salaryListing].sort((a, b) =>
        (
          a.firstname ||
          a.last_name ||
          a.year ||
          a.Total_Salary ||
          a.Basic_Salary ||
          a.Professional_Tax ||
          a.House_Rent_Allow ||
          a.Income_Tax ||
          a.Other_Allownces ||
          a.Gratuity ||
          a.Performance_Allownces ||
          a.Provident_Fund ||
          a.Bonus ||
          a.ESIC ||
          a.Other ||
          a.Other_Deduction ||
          a.EL_Encash_Amount ||
          a.Total_Deduction ||
          a.Gross_Salary ||
          a.Net_Salary
        ).toLowerCase() >
          (
            b.firstname ||
            b.last_name ||
            b.year ||
            b.Total_Salary ||
            b.Basic_Salary ||
            b.Professional_Tax ||
            b.House_Rent_Allow ||
            b.Income_Tax ||
            b.Other_Allownces ||
            b.Gratuity ||
            b.Performance_Allownces ||
            b.Provident_Fund ||
            b.Bonus ||
            b.ESIC ||
            b.Other ||
            b.Other_Deduction ||
            b.EL_Encash_Amount ||
            b.Total_Deduction ||
            b.Gross_Salary ||
            b.Net_Salary
          ).toLowerCase()
          ? 1
          : -1
      );
      setSalaryListing(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...salaryListing].sort((a, b) =>
        (
          a.firstname ||
          a.last_name ||
          a.year ||
          a.Total_Salary ||
          a.Basic_Salary ||
          a.Professional_Tax ||
          a.House_Rent_Allow ||
          a.Income_Tax ||
          a.Other_Allownces ||
          a.Gratuity ||
          a.Performance_Allownces ||
          a.Provident_Fund ||
          a.Bonus ||
          a.ESIC ||
          a.Other ||
          a.Other_Deduction ||
          a.EL_Encash_Amount ||
          a.Total_Deduction ||
          a.Gross_Salary ||
          a.Net_Salary
        ).toLowerCase() <
          (
            b.firstname ||
            b.last_name ||
            b.year ||
            b.Total_Salary ||
            b.Basic_Salary ||
            b.Professional_Tax ||
            b.House_Rent_Allow ||
            b.Income_Tax ||
            b.Other_Allownces ||
            b.Gratuity ||
            b.Performance_Allownces ||
            b.Provident_Fund ||
            b.Bonus ||
            b.ESIC ||
            b.Other ||
            b.Other_Deduction ||
            b.EL_Encash_Amount ||
            b.Total_Deduction ||
            b.Gross_Salary ||
            b.Net_Salary
          ).toLowerCase()
          ? 1
          : -1
      );
      setSalaryListing(sorted);
      setOrder("ASC");
    }
  };

  return (
    <div>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Salary Structure Listing</h4>
                </div>
                <div className="Add-Permission">
                  <Link to="add">
                    <h4 className="btn btn-primary">
                      <i
                        className="fa-solid fa-plus"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Add Salary Structure
                    </h4>
                  </Link>
                </div>
              </Card.Header>

              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped sticky-header-table-last"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>
                          <span className="title">No</span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("firstname, last_name")}
                        >
                          <span className="title">Employee Name</span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("year")}
                        >
                          <span className="title">Year </span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Total_Salary")}
                        >
                          <span className="title">Total Salary</span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Basic_Salary")}
                        >
                          <span className="title">Basic Salary</span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Professional_Tax")}
                        >
                          <span className="title">Professional Tax</span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("House_Rent_Allow")}
                        >
                          <span className="title">HRA </span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Income_Tax")}
                        >
                          <span className="title">Income Tax</span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Other_Allownces")}
                        >
                          <span className="title">Other Allowances </span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Gratuity")}
                        >
                          <span className="title">Gratuity</span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Performance_Allownces")}
                        >
                          <span className="title">
                            Performance Allowances
                          </span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Provident_Fund")}
                        >
                          <span className="title">Provident Fund</span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Bonus")}
                        >
                          <span className="title">Bonus </span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("ESIC")}
                        >
                          <span className="title">ESIC </span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Other")}
                        >
                          <span className="title">Other </span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Other_Deduction")}
                        >
                          <span className="title">Other Deduction</span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("EL_Encash_Amount")}
                        >
                          <span className="title">EL Encashments </span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Total_Deduction")}
                        >
                          <span className="title">Total Deduction</span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Gross_Salary")}
                        >
                          <span className="title">Gross Salary</span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("Net_Salary")}
                        >
                          <span className="title">Net Salary</span>
                        </th>
                        <th min-width="100px">Action</th>
                      </tr>
                    </thead>
                    {loading ? (
                      <tbody>
                        <tr>
                          <td colSpan={20} className='text-start'>
                            <Spinner animation="border" variant="primary" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody className="text-capitalize">
                        {salaryListing.length > 0 ? (
                          salaryListing
                            .slice(0)
                            .map((item, index) => (
                              <tr key={index} className="border-light">
                                <td>{index + 1}</td>
                                <td>
                                  {item.userData[0] && item.userData[0].firstname}
                                </td>
                                <td>{item.year} </td>
                                <td>{item.Total_Salary}</td>
                                <td>{item.Basic_Salary}</td>
                                <td>{item.Professional_Tax}</td>
                                <td>{item.House_Rent_Allow}</td>
                                <td>{item.Income_Tax}</td>
                                <td>{item.Other_Allownces}</td>
                                <td>{item.Gratuity}</td>
                                <td>{item.Performance_Allownces}</td>
                                <td>{item.Provident_Fund}</td>
                                <td>{item.Bonus} </td>
                                <td>{item.ESIC} </td>
                                <td>{item.Other} </td>
                                <td>{item.Other_Deduction}</td>
                                <td>{item.EL_Encash_Amount}</td>
                                <td>{item.Total_Deduction}</td>
                                <td>{item.Gross_Salary}</td>
                                <td>{item.Net_Salary}</td>

                                <td>
                                  <div className="flex align-items-center list-user-action">
                                    <Link className="btn btn-sm btn-icon btn-warning" style={{ marginRight: "5px" }} data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to={`edit/${item._id}`}>
                                      <span className="btn-inner">
                                        <svg
                                          width="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M15.1655 4.60254L19.7315 9.16854"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center">
                              <span className="" aria-hidden="true"></span>
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SalaryStructureList;

