import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import { PermissionData } from "../../layouts/dashboard/default";
import { showToast } from "../../plugins/toastUtils";

const EntryList = ({ api, user_data }) => {
  const Permissions = useContext(PermissionData);
  let currentMonth = new Date().getMonth() + 1;
  let currentYear = new Date().getFullYear();
  const [entry, setEntry] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [userId, setUserId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [deleteid, setDeleteid] = useState("");
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [paginationEntry] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [dataperPage, setDataperPage] = useState(paginationEntry);
  const navigate = useNavigate();
  let lastTimeEntryData = entry.slice(-1);

  const Entrydatalist = async () => {
    setLoading(true);
    api.get(
      `/timeEntries?page=${page}&limit=${dataperPage}&dateSort=${order}&year=${year}&month=${month}&userId=${userId}&projectId=${projectId}`
    ).then((response) => {
      if (response.data.status === false) {
        navigate("/403");
      }
      if (response.data !== undefined) {
        setLoading(false);
        setEntry(response.data.timeEntryData);
        setUserList(response.data.userData);
        setTotalPages(response.data.totalPages);
        setTotalData(response.data.totalData);
        if (user_data.roleData[0].role_name === "Admin") {
          setProjectList(response.data.projectData);
        } else {
          setProjectList(response.data.userprojectData);
        }
      }
    }).catch((error) => {
      if (error.response.status == 403) {
        navigate("/403");
      }
      console.log("error", error);
    });
  };

  const Handledelete = () => {
    const id = deleteid;
    setLoading(true);
    if (id !== undefined) {
      api
        .post(`/deleteTimeEntry/${id}`, "")
        .then((response) => {
          setTimeout(() => {
            showToast(response.data.message, "success");
          }, 100);
          setShow(false);
          Entrydatalist();
        })
        .catch((error) => {
          showToast(error.response.data.errors, "error");
          setLoading(false);
        });
    }
    setLoading(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  function deletemodel(id) {
    setDeleteid(id);
    setShow(true);
  }

  const PageChange = ({ selected }) => {
    setPage(selected + 1);
  };

  const setPerPage = (value) => {
    setDataperPage(value);
    setPage(1);
  };

  const sorting = () => {
    if (order === "ASC") {
      setOrder("DSC");
    }
    if (order === "DSC") {
      setOrder("ASC");
    }
  };

  const HandleMonth = (e) => {
    setMonth(e.target.value);
    Entrydatalist();
  };

  const HandleYear = (e) => {
    setYear(e.target.value);
    Entrydatalist();
  };

  useEffect(() => {
    Entrydatalist();
  }, [month, year, userId, page, dataperPage, order, projectId]);

  return (
    <>
      <div>
        <Row>
          <Modal show={show} onHide={handleClose} className="text-center">
            <Modal.Header closeButton>
              <Modal.Title style={{ marginLeft: "5rem" }}>
                Do you want to delete it ?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button
                className="me-2"
                variant="primary"
                disabled={loading}
                onClick={() => Handledelete()}
              >
                {loading ? "Deleting..." : "Delete"}
              </Button>
              <Button
                variant="danger"
                onClick={() => handleClose()}
                value="Cancel"
              >
                Cancel
              </Button>
            </Modal.Body>
          </Modal>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Time Entries</h4>
                </div>
                <div className="entry-button d-flex justify-content-end">
                  {Permissions.includes("Add TimeEntry") && (
                    <div className="Add-Entries me-2">
                      <Link
                        to={{
                          pathname: "/time-entry/add",
                          state: { from: "/time-entry" },
                        }}
                      >
                        {" "}
                        <h4 className="btn btn-primary">
                          <i
                            className="fa-solid fa-plus"
                            style={{ marginRight: "5px" }}
                          ></i>
                          New Entry
                        </h4>
                      </Link>
                    </div>
                  )}
                </div>
              </Card.Header>

              <Card.Header className={`d-flex justify-content-between`}>
                <div className="row align-items-center w-100">
                  <div className="col-md-2 mb-3">
                    <div
                      className="dataTables_length d-flex align-items-center"
                      id="user-list-table_length"
                      style={{ gap: "2%" }}
                    >
                      <span>Show</span>
                      <select
                        name="user-list-table_length"
                        aria-controls="user-list-table"
                        className="form-select form-select-sm"
                        onChange={(e) => setPerPage(e.target.value)}
                        defaultValue={paginationEntry}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2 mb-3">
                    <div
                      className="dataTables_length d-flex align-items-center"
                      id="user-list-table_length"
                      style={{ gap: "2%" }}
                    >
                      <span>Month</span>
                      <select
                        id="monthSelect"
                        className="form-select form-select-sm"
                        onChange={(e) => HandleMonth(e)}
                        defaultValue={currentMonth}
                      >
                        <option value="" disabled>
                          select
                        </option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2 mb-3">
                    <div
                      className="dataTables_length d-flex align-items-center"
                      id="user-list-table_length"
                      style={{ gap: "2%" }}
                    >
                      <span>Year</span>
                      <select
                        id="yearSelect"
                        className="form-select form-select-sm"
                        onChange={(e) => HandleYear(e)}
                        defaultValue={currentYear}
                      >
                        <option value={`${moment().year() - 2}`}>
                          {moment().year() - 2}
                        </option>
                        <option value={`${moment().year() - 1}`}>
                          {moment().year() - 1}
                        </option>
                        <option value={`${moment().year()}`}>
                          {moment().year()}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2 mb-3">
                    <div
                      className="dataTables_length d-flex align-items-center"
                      id="user-list-table_length"
                      style={{ gap: "2%" }}
                    >
                      <label>Projects</label>
                      <select
                        name="user-list-table_length"
                        aria-controls="user-list-table"
                        className="form-select form-select-sm w-75"
                        onChange={(e) => setProjectId(e.target.value)}
                      >
                        <option value="">All</option>
                        {projectList &&
                          projectList.map((item, index) => {
                            return (
                              <option value={item._id} key={item.id}>
                                {item.title}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2 mb-3">
                    {Permissions.includes("View Dropdown") && (
                      <div
                        className="dataTables_length d-flex align-items-center"
                        id="user-list-table_length"
                        style={{ gap: "2%" }}
                      >
                        <label>Employee</label>
                        <select
                          name="user-list-table_length"
                          aria-controls="user-list-table"
                          className="form-select form-select-sm w-75"
                          onChange={(e) => setUserId(e.target.value)}
                        >
                          <option value="">All</option>
                          {userList &&
                            userList?.map((item, index) => (
                              <option
                                value={item._id}
                                key={index + "firstName"}
                              >
                                {item?.firstname + " " + item?.last_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="light">
                        <th>No</th>
                        {user_data.roleData[0].role_name === "Admin" && (
                          <th>
                            <span className="title">UserName</span>
                          </th>
                        )}
                        <th
                          min-width="50px"
                          style={{ whiteSpace: "break-spaces" }}
                        >
                          <span className="title">Project Name</span>
                        </th>
                        <th>
                          <span className="title">Task Name</span>
                        </th>
                        <th>
                          <span className="title">Total hours</span>
                        </th>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => sorting("date")}
                        >
                          <span className="title">Date</span>
                        </th>
                        {(Permissions.includes("Update TimeEntry") ||
                          Permissions.includes("Delete TimeEntry")) && (
                            <th min-width="100px">Action</th>
                          )}
                      </tr>
                    </thead>
                    {loading ? (
                      <tbody>
                        <tr>
                          <td colSpan={20} className="text-center">
                            <Spinner
                              animation="border"
                              variant="primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody className="text-capitalize">
                        {entry?.length > 0 ? (
                          entry?.map((item, index) => (
                            <tr key={index} className="border-light">
                              <td>{item.index}</td>
                              {user_data.roleData[0].role_name === "Admin" && (
                                <td>
                                  {item.userData && item.userData[0]?.firstname}
                                </td>
                              )}
                              <td
                                style={{
                                  minWidth: "300px",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {item.projectData?.map((data, index) => {
                                  return <span key={index}>{data.title}</span>;
                                })}
                              </td>
                              <td>
                                {item.taskData?.map((data, index) => {
                                  return <span key={index}>{data.title}</span>;
                                })}
                              </td>
                              <td>{item.hours}</td>
                              <td>{moment(item.date).format("DD/MM/YYYY")}</td>
                              {(Permissions.includes("Update TimeEntry") ||
                                Permissions.includes("Delete TimeEntry")) && (
                                  <td>
                                    <div className="flex align-items-center list-user-action">
                                      {Permissions.includes(
                                        "Update TimeEntry"
                                      ) && (
                                          <Link
                                            className="btn btn-sm btn-icon btn-warning"
                                            style={{ marginRight: "5px" }}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=""
                                            data-original-title="Edit"
                                            to={`edit/${item._id}`}
                                          >
                                            <span className="btn-inner">
                                              <svg
                                                width="20"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                                  stroke="currentColor"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                ></path>
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                                  stroke="currentColor"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                ></path>
                                                <path
                                                  d="M15.1655 4.60254L19.7315 9.16854"
                                                  stroke="currentColor"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                ></path>
                                              </svg>
                                            </span>
                                          </Link>
                                        )}
                                      {Permissions.includes(
                                        "Delete TimeEntry"
                                      ) && (
                                          <Link
                                            onClick={() => deletemodel(item._id)}
                                            className="btn btn-sm btn-icon btn-danger"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=""
                                            data-original-title="Delete"
                                            to="#"
                                          >
                                            <span className="btn-inner">
                                              <svg
                                                width="20"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                stroke="currentColor"
                                              >
                                                <path
                                                  d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                  stroke="currentColor"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                ></path>
                                                <path
                                                  d="M20.708 6.23975H3.75"
                                                  stroke="currentColor"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                ></path>
                                                <path
                                                  d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                  stroke="currentColor"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                ></path>
                                              </svg>
                                            </span>
                                          </Link>
                                        )}
                                    </div>
                                  </td>
                                )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={99} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
                <div className="row align-items-center w-100 mt-2">
                  <div className="col-md-6">
                    <div
                      className="dataTables_length d-flex align-items-center mx-3"
                      id="user-list-table_length"
                    >
                      {entry?.length > 0 && (
                        <label>
                          Showing {entry[0]?.index} to{" "}
                          {lastTimeEntryData[0]?.index} of {totalData} entries
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <ReactPaginate
                      nextLabel="Next"
                      onPageChange={PageChange}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={totalPages}
                      previousLabel="Previous"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      disabledClassName={"disabled"}
                      activeClassName={"active"}
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination float-end"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EntryList;
