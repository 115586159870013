import * as FileSaver from 'file-saver'
import moment from 'moment/moment'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Image, Modal, Row, Spinner } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'
import '../../assets/css/pagination.css'
import avatars1 from '../../assets/images/avatars/01.png'
import avatars2 from '../../assets/images/avatars/avtar_1.png'
import avatars3 from '../../assets/images/avatars/avtar_2.png'
import avatars4 from '../../assets/images/avatars/avtar_3.png'
import avatars5 from '../../assets/images/avatars/avtar_4.png'
import avatars6 from '../../assets/images/avatars/avtar_5.png'
import Card from '../../components/Card'
import { PermissionData } from '../../layouts/dashboard/default'
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper'
import { showToast } from '../../plugins/toastUtils'

const EmployeeList = ({ api, imagePath }) => {

   const Permissions = useContext(PermissionData);
   const themeData = useSelector((state) => state.mode.colorcustomizer);
   const navigate = useNavigate();
   const [Employee, setEmployee] = useState([]);
   const [searchValue, setSearchValue] = useState('');
   const [loading, setLoading] = useState(true);
   const [deleteid, setDeleteid] = useState('');
   const [show, setShow] = useState(false);
   const [multiDelete, setMultiDelete] = useState([]);
   const [multidelshow, setMultidelshow] = useState(false);
   const [order, setOrder] = useState('ASC');
   const [totalData, setTotalData] = useState("");
   const [totalPages, setTotalPages] = useState(1);
   const [search, setSearch] = useState(false);
   const [page, setPage] = useState(1);
   const [entry] = useState(10);
   const [dataperPage, setDataperPage] = useState(entry);
   let lastEmployeeData = Employee.slice(-1)

   const employeedata = () => {
      api.get(`/userListing?search=${searchValue}&page=${page}&limit=${dataperPage}&nameSort=${order}`).then((response) => {
         setLoading(false);
         if (response.data !== undefined) {
            if (response.data.status === false) {
               navigate('/403')
            }
            setTotalPages(response.data.totalPages)
            setTotalData(response.data.totalData)
            setEmployee((response.data.userData));
         }
      }).catch((error) => {
         console.log("error", error);
         showToast(error.response.data.errors, 'error');
      });
   }

   const Handledelete = () => {
      const id = deleteid;
      setLoading(true);
      if (id !== undefined) {
         api.post(`/deleteUser/${id}`, '').then((response) => {
            setShow(false);
            employeedata();
            showToast(response.data.message, "success")
         }).catch((error) => {
            console.log("error", error);
            showToast(error.response.data.errors, 'error');
         });
      }
      setLoading(false);
   }

   const handleClose = () => {
      setShow(false);
   }

   function deletemodel(id) {
      setDeleteid(id);
      setShow(true);
   }

   const HandleMultiDelete = (e) => {
      const { name, checked } = e.target;
      if (checked) {
         setMultiDelete([...multiDelete, name]);
         Employee.map((d) => d._id === name ? { ...d, isChecked: checked } : d)
      }
      else {
         setMultiDelete(multiDelete.filter((e) => e !== name));
      }
   }

   const PostMultidelete = async () => {
      setLoading(true);
      if (multiDelete.length > 0) {
         await api.post(`/deleteMany/`, {
            multiDelete
         }).then((response) => {
            setMultidelshow(false);
            setMultiDelete([]);
            employeedata();
            showToast(response.data.message, "success")
         }).catch((error) => {
            console.log("error", error);
            showToast(error.response.data.errors, 'error');
         });
      }
      setLoading(false);
   }

   const handleMultidelClose = () => {
      setMultidelshow(false);
   }

   function Multideletemodel() {
      setMultidelshow(true);
   }

   const PageChange = ({ selected }) => {
      setPage(selected + 1);
   }

   const setPerPage = (value) => {
      setDataperPage(value)
      setPage(1)
   }

   const sorting = () => {
      if (order === 'ASC') {
         setOrder('DSC')
      }
      if (order === 'DSC') {
         setOrder('ASC')
      }
   }

   const handleSearch = async (event) => {
      if (searchValue && event.key === 'Enter') {
         setPage(1)
         setSearch(true)
         employeedata()
      }
   }

   const ExportAsXlsx = async () => {
      const fileType = 'xlsx';
      const ws = XLSX.utils.json_to_sheet(Employee);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, 'EmployeeData' + '.xlsx')
   }

   useEffect(() => {
      if (!searchValue && search) {
         employeedata()
      }
   }, [searchValue]);

   useEffect(() => {
      employeedata()
   }, [page, dataperPage, order]);

   return (
      <>
         <div>
            <Row>
               <Col sm="12">

                  <Modal show={show} onHide={handleClose} className='text-center'>
                     <Modal.Header closeButton>
                        <Modal.Title style={{ marginLeft: "5rem" }}>Do you want to delete it ?</Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                        <Button variant="primary" className='me-2' disabled={loading} onClick={() => Handledelete()}>{loading ? 'Deleting...' : 'Delete'}</Button>
                        <Button variant="danger" onClick={() => handleClose()} value="Cancel" >Cancel</Button>

                     </Modal.Body>
                  </Modal>

                  <Modal show={multidelshow} onHide={handleMultidelClose} className='text-center'>
                     <Modal.Header closeButton>
                        <Modal.Title style={{ marginLeft: "5rem" }}>Do you want to delete it ?</Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                        <Button variant="primary" className='me-2' disabled={loading} onClick={() => PostMultidelete()}>{loading ? 'Deleting...' : 'Delete'}</Button>
                        <Button variant="danger" onClick={() => handleMultidelClose()} value="Cancel" >Cancel</Button>
                     </Modal.Body>
                  </Modal>

                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Employee Listing</h4>
                        </div>
                        {Permissions.includes('Add Employee') &&
                           <div className="Add-user">
                              <Link to='add' className='add-emp' style={{ marginRight: "5px" }}> <h4 className="btn btn-primary">
                                 <i className="fa-solid fa-plus" style={{ marginRight: "5px" }}></i>
                                 Add Employee</h4>
                              </Link>
                              <Link to='junk'> <h4 className="btn btn-danger text-white junk-employee">
                                 <svg width="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "5px", transform: "rotate(180deg)" }}><path fillRule="evenodd" clipRule="evenodd" d="M21.25 12C21.25 6.892 17.109 2.75 12 2.75C6.892 2.75 2.75 6.892 2.75 12C2.75 17.108 6.892 21.25 12 21.25C17.109 21.25 21.25 17.108 21.25 12Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M15.4712 13.4424L12.0002 9.95638L8.52919 13.4424" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                 Junk</h4>
                              </Link>
                           </div>
                        }
                     </Card.Header>
                     <Card.Header className={`d-flex justify-content-between`}>
                        <div className="row align-items-center w-100">
                           <div className="col-lg-6 col-md-12">
                              <div className="dataTables_length d-flex align-items-center" id="user-list-table_length" style={{ gap: '2%' }}>
                                 <label>Show</label>
                                 <select
                                    name="user-list-table_length" aria-controls="user-list-table"
                                    className="form-select form-select-sm w-25 emp-filter" defaultValue={entry} onChange={(e) => setPerPage(e.target.value)}>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                 </select>
                                 {multiDelete?.length > 0 &&
                                    <button type="button" className={`d-flex bg-danger border-0 btn btn-primary p-0 px-1 py-1`} style={{ position: 'relative', float: 'right' }} onClick={() => Multideletemodel()}>
                                       <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path opacity="0.4" d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z" fill="currentColor" />
                                          <path d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z" fill="currentColor" />
                                       </svg>
                                    </button>
                                 }
                                 <div className="export-data">
                                    <div className="export-as-xlsx" style={{ zIndex: '99', cursor: 'pointer' }} onClick={() => ExportAsXlsx()}>
                                       <img src="https://cdn1.iconfinder.com/data/icons/social-media-glossy/512/79-excel_social-512.png" height='35px' alt="" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-lg-6 col-md-12">
                              <div id="user-list-table_filter" className="dataTables_filter d-flex align-items-center justify-content-end" style={{ gap: '2%' }}>
                                 <label>Search:</label>

                                 <input type="search" className="form-control form-control-sm w-50" placeholder=""
                                    aria-controls="user-list-table"
                                    onChange={(event) => setSearchValue(event.target.value)}
                                    onKeyDown={handleSearch}
                                 />
                              </div>
                           </div>
                        </div>
                     </Card.Header>

                     <Card.Body className="px-0">
                        <div className="table-responsive" id='emp-scroll'>
                           <table id="user-list-table" className="table table-striped sticky-header-table-last" role="grid" data-toggle="data-table">
                              <thead>
                                 <tr className="ligth">
                                    {Permissions.includes('Delete Employee') && (
                                       <th>#</th>
                                    )}
                                    <th>No</th>
                                    <th>Profile</th>
                                    <th>
                                       <div className=" d-flex ">
                                          <span className="title">Employee Code</span>
                                       </div>
                                    </th>
                                    <th style={{ cursor: 'pointer' }} onClick={() => sorting('firstname')}>
                                       <div className=" d-flex ">
                                          <span className="title">Name</span>
                                       </div>
                                    </th>
                                    <th>
                                       <div className=" d-flex ">
                                          <span className="title">Contact</span>
                                       </div>
                                    </th>
                                    <th >
                                       <div className=" d-flex ">
                                          <span className="title">Email</span>
                                       </div>
                                    </th>
                                    <th>
                                       <div className=" d-flex ">
                                          <span className="title">Status</span>
                                       </div>
                                    </th>
                                    <th>
                                       <div className=" d-flex ">
                                          <span className="title">Join Date</span>
                                       </div>
                                    </th>
                                    {(Permissions.includes('View UserPermissions') ||
                                       Permissions.includes('View Employees Details') ||
                                       Permissions.includes('Delete Employee') ||
                                       Permissions.includes('Update Employee')) &&
                                       <th min-width="100px">Action</th>
                                    }
                                 </tr>
                              </thead>
                              {loading ?
                                 <tbody><tr><td colSpan={99} className='text-center'>
                                    <Spinner animation="border" variant="primary" role="status">
                                       <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                 </td></tr></tbody>
                                 :
                                 <tbody>
                                    {Employee?.length == 0 ?
                                       <tr>
                                          <td colSpan='20' className='text-center'>No Search results found</td>
                                       </tr>
                                       :
                                       Employee?.length > 0 ?
                                          Employee.map((item, index) => {
                                             return (<tr key={index} className="border-light">
                                                {Permissions.includes(
                                                   "Delete Employee"
                                                ) && (
                                                      <td>
                                                         <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name={item._id}
                                                            defaultChecked={item.isChecked}
                                                            onChange={(e) => HandleMultiDelete(e)}
                                                         />
                                                      </td>
                                                   )}
                                                <td>{item.index}</td>
                                                <td><Image className="bg-soft-primary rounded-circle img-fluid " src={item.photo ? imagePath + item.photo :
                                                   themeData === 'theme-color-default' ? avatars1 :
                                                      themeData === 'theme-color-blue' ? avatars2 :
                                                         themeData === 'theme-color-gray' ? avatars3 :
                                                            themeData === 'theme-color-red' ? avatars5 :
                                                               themeData === 'theme-color-yellow' ? avatars6 :
                                                                  themeData === 'theme-color-pink' && avatars4
                                                }
                                                   alt="profile-pic" style={{ height: '50px', width: '50px' }} /></td>
                                                <td>{item.emp_code}</td>
                                                <td>{item.firstname}</td>
                                                <td>{item.mo_number}</td>
                                                <td>{item.company_email}</td>
                                                <td><span style={{ fontWeight: '200' }} className={`badge ${item.status === "ExEmployee" ? "bg-danger" : item.status === "Pending" ? "bg-warning" : item.status === "InActive" ? "bg-info" : item.status === "Active" ? 'bg-success' : "bg-warning"} p-2 w-100`}>{item.status}</span></td>
                                                <td>{moment(item.doj).format("DD/MM/YYYY")}</td>
                                                {(Permissions.includes('View UserPermissions') ||
                                                   Permissions.includes('View Employees Details') ||
                                                   Permissions.includes('Delete Employee') ||
                                                   Permissions.includes('Update Employee')) &&
                                                   <td>
                                                      <div className="d-flex" style={{ gap: "3px" }}>
                                                         <div>
                                                            {Permissions.includes('View UserPermissions') &&
                                                               <Link className="btn btn-sm btn-icon btn-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Add" to={`permission/${item._id}`} >
                                                                  <span className="btn-inner">
                                                                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                                           d="M11.9846 21.606C11.9846 21.606 19.6566 19.283 19.6566 12.879C19.6566 6.474 19.9346 5.974 19.3196 5.358C18.7036 4.742 12.9906 2.75 11.9846 2.75C10.9786 2.75 5.26557 4.742 4.65057 5.358C4.03457 5.974 4.31257 6.474 4.31257 12.879C4.31257 19.283 11.9846 21.606 11.9846 21.606Z"
                                                                           stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                        <path d="M9.38574 11.8746L11.2777 13.7696L15.1757 9.86963" stroke="currentColor" strokeWidth="1.5"
                                                                           strokeLinecap="round" strokeLinejoin="round" />
                                                                     </svg>
                                                                  </span>
                                                               </Link>
                                                            }
                                                         </div>
                                                         <div>
                                                            {Permissions.includes('View Employees Details') &&
                                                               <Link className="btn btn-sm btn-icon btn-success" data-toggle="tooltip" data-placement="top" title="Profile" data-original-title="Add" to={`profile/${item._id}`}>
                                                                  <span className="btn-inner">
                                                                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z" fill="currentColor" />
                                                                        <path opacity="0.4" d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z" fill="currentColor" />
                                                                     </svg>
                                                                  </span>
                                                               </Link>
                                                            }
                                                         </div>
                                                         <div>
                                                            {Permissions.includes('Update Employee') &&
                                                               <Link className="btn btn-sm btn-icon btn-warning" data-toggle="tooltip" data-placement="top" title="" data-original-title="" to={`edit/${item._id}`}>
                                                                  <span className="btn-inner">
                                                                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                     </svg>
                                                                  </span>
                                                               </Link>
                                                            }
                                                         </div>
                                                         <div>
                                                            {Permissions.includes('Delete Employee') &&
                                                               <Link onClick={() => deletemodel(item._id)} className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="" to='#'>
                                                                  <span className="btn-inner">
                                                                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                                                        <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                     </svg>
                                                                  </span>
                                                               </Link>
                                                            }
                                                         </div>
                                                      </div>
                                                   </td>}
                                             </tr>)
                                          }) : (
                                             <tr>
                                                <td colSpan="99" className='text-center'>
                                                   No data found
                                                </td>
                                             </tr>
                                          )
                                    }
                                 </tbody>
                              }
                           </table>
                        </div>
                        <div className="row align-items-center w-100 mt-2">
                           <div className="col-md-6">
                              <div className="dataTables_length d-flex align-items-center mx-3" id="user-list-table_length">
                                 {Employee?.length > 0 && (
                                    <label>
                                       Showing {Employee[0]?.index} to {lastEmployeeData[0]?.index} of {totalData} entries
                                    </label>
                                 )}
                              </div>
                           </div>

                           <div className="col-md-6">
                           <ReactPaginate
                        nextLabel="Next"
                        onPageChange={PageChange}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={totalPages}
                        previousLabel="Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination float-end"
                        renderOnZeroPageCount={null}
                      />
                              {/* <div id="user-list-table_filter" className="dataTables_filter d-flex align-items-center justify-content-end" style={{ gap: '2%', position: "relative", right: '30%' }}>
                                 {Employee?.length > 0 && (
                                    <div className="dataTables_paginate paging_simple_numbers" id="user-list-table_paginate">
                                       <ReactPaginate
                                          previousLabel={'Previous'}
                                          nextLabel={'Next'}
                                          pageCount={totalPages}
                                          onPageChange={PageChange}
                                          containerClassName={'pagination align-items-center'}
                                          previousClassName={'paginate_button page-item previous'}
                                          previousLinkClassName={'page-link border-0'}
                                          nextLinkClassName={'page-link border-0'}
                                          nextClassName={'paginate_button page-item next border-none'}
                                          disabledClassName={'disabled'}
                                          activeClassName={'active'}
                                          breakLabel="..."
                                       />
                                    </div>
                                 )}
                              </div> */}
                           </div>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
               <ToastContainerWrapper />
            </Row>
         </div >
      </>
   )
}

export default EmployeeList;