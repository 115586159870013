import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/scss/cc-custom/timeEntry.css";
import Card from "../../components/Card";
import { PermissionData } from "../../layouts/dashboard/default";

const TimeEntryHistory = ({ api, user_data }) => {
  const navigate = useNavigate();
  const Permissions = useContext(PermissionData);
  const themeMode = useSelector((state) => state.mode);
  useEffect(() => {
    localStorage.setItem("time-entry-path", "time-entry-history");
    return () => { };
  }, []);
  let currentMonth = new Date().getMonth() + 1;
  let currentYear = new Date().getFullYear();
  const [entry, setEntry] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allHoliday, setAllHoliday] = useState([]);
  const [allLeaves, setAllLeaves] = useState([]);
  const [cancelToken, setCancelToken] = useState(null);
  const [user, setUser] = useState("");
  const [userList, setUserList] = useState([]);
  const [allSunday, setAllSunday] = useState([]);
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const daysInMonth = new Date(new Date().getFullYear(), month, 0).getDate();
  const dateValues = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const userid = user_data._id;

  useEffect(() => {
    setUser(userid);
    return () => { };
  }, [userid]);

  const HandleMonth = (e) => {
    setMonth(parseInt(e.target.value));
  };

  const HandleYear = (e) => {
    setYear(e.target.value);
  };

  useEffect(() => {
    Entrydatalist();
  }, [user, year, month]);

  const Entrydatalist = async () => {
    if (cancelToken) {
      cancelToken.cancel("New request initiated");
    }
    const source = axios.CancelToken.source();
    setCancelToken(source);
    try {
      if (user) {
        const response = await api.post(`/NewTimeEntryListing`, {
          user: user,
          month: month,
          year: year !== "" ? year : currentYear,
        });
        setEntry(response.data.timeEntryData);
        setUserList(response.data.userData);
        const holidays = response.data.holidayData.map((item) =>
          parseInt(
            new Date(item.holiday_date).toLocaleDateString("en-US", {
              day: "2-digit",
            })
          )
        );
        setAllHoliday(holidays);
        const allLeaves = [];
        response.data.leavesData.forEach((leave) => {
          const datefrom = new Date(leave.datefrom);
          const dateto = new Date(leave.dateto);
          const this_month = parseInt(month);
          for (let d = datefrom; d <= dateto; d.setDate(d.getDate() + 1)) {
            if (d.getMonth() + 1 === this_month) {
              allLeaves.push(
                parseInt(
                  new Date(d).toLocaleDateString("en-US", { day: "2-digit" })
                )
              );
            }
          }
        });
        setAllLeaves(allLeaves);
      }
    } catch (error) {
      if (error.response.status == 403) {
        navigate("/403");
      }
      console.log("error", error);
    }
  };

  const projectTasks = [];

  entry.forEach((entry) => {
    Object.keys(entry).forEach((projectName) => {
      Object.keys(entry[projectName]).forEach((task) => {
        const duration = entry[projectName][task][0];
        const dayOfWeek = entry[projectName][task][1];
        const projectTask = `${projectName}/${task}`;
        projectTasks.push(projectTask);
      });
    });
  });

  function getSundaysOfMonth(year, month) {
    const sundays = [];
    var currentMonth = month - 1;
    const date = new Date(year, currentMonth, 1);
    while (date.getMonth() === currentMonth) {
      if (date.getDay() === 0) {
        sundays.push(new Date(date));
      }
      date.setDate(date.getDate() + 1);
    }
    return sundays;
  }

  useEffect(() => {
    function getSundaysOfMonth(year, month) {
      const sundays = [];
      const date = new Date(year, month - 1, 1);
      while (date.getMonth() === month - 1) {
        if (date.getDay() === 0) {
          sundays.push(new Date(date));
        }
        date.setDate(date.getDate() + 1);
      }
      return sundays;
    }

    function getSaturdaysOfMonth(year, month) {
      const saturdays = [];
      const date = new Date(year, month - 1, 1);
      while (date.getMonth() === month - 1) {
        if (date.getDay() === 6) {
          saturdays.push(new Date(date));
        }
        date.setDate(date.getDate() + 1);
      }
      return saturdays;
    }

    const sundays = getSundaysOfMonth(year, month);
    const saturdays = getSaturdaysOfMonth(year, month);

    const weekendDays = [...sundays, ...saturdays];

    const uniqueValues = new Set(
      weekendDays.map((day) =>
        parseInt(day.toLocaleDateString("en-US", { day: "2-digit" }))
      )
    );

    setAllSunday(Array.from(uniqueValues));
  }, [month, year]);

  const columnTotals = Array.from({ length: daysInMonth }, (_, index) => {
    const columnHours = projectTasks?.reduce((total, projectTask) => {
      const [projectName, taskName] = projectTask.split("/");
      const taskList = entry[0][projectName][taskName];
      const matchingTasks = taskList?.filter(
        (task) => task[Object.keys(task)]._day === String(index + 1)
      );
      const taskHours = matchingTasks?.reduce(
        (total, task) => total + parseFloat(task[Object.keys(task)].h) || 0,
        0
      );
      const isSunday = allSunday.includes(index + 1);
      const isLeave = allLeaves.includes(index + 1);
      const isHoliday = allHoliday.includes(index + 1);
      console.log("taskHours :::::: ",taskHours)
      return (
        total +
        (!isSunday && !isHoliday && !isLeave
          ? taskHours
          : Math.floor(taskHours))
      );
    }, 0);
    return columnHours;
  });
  const renderTableRows = (projectTask) => {
    const [projectName, taskName] = projectTask.split("/");
    const taskList = entry[0][projectName][taskName];

    const rows = Array.from({ length: daysInMonth }, (_, index) => {
      const isSunday = allSunday.includes(index + 1);
      const isLeave = allLeaves.includes(index + 1);
      const isHoliday = allHoliday.includes(index + 1);
      const matchingEntries = taskList?.filter((task) => {
        return task[Object.keys(task)]._day === String(index + 1);
      });

      const hours = matchingEntries?.reduce((total, entry) => {
        return total + parseFloat(entry[Object.keys(entry)].h);
      }, 0);

      return (
        <td
          key={index + 1}
          className={`${isSunday
            ? "text-danger "
            : isHoliday
              ? "text-warning "
              : isLeave
                ? themeMode.scheme === "dark"
                  ? "text-info "
                  : "text-primary "
                : ""
            }text-center`}
        >
          {" "}
          {isSunday && isHoliday
            ? "S"
            : isHoliday
              ? "H"
              : isSunday
                ? "S"
                : isLeave
                  ? "L"
                  : hours > 0
                    ? hours
                    : ""}
        </td>
      );
    });
    const rowTotal = taskList?.reduce(
      (total, task) => total + parseFloat(task[Object.keys(task)].h) || 0,
      0
    );
console.log(rowTotal, " :::::rowTotal")
    return { rows, rowTotal };
  };

  useEffect(() => {
    localStorage.setItem("time-entry-path", window.location.pathname);
  }, []);

  useEffect(() => {
    const currentYear = new Date().getFullYear().toString();
    setYear(currentYear);
  }, []);

  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Time Entry History</h4>
                </div>
                {Permissions.includes("Add TimeEntry") && (
                  <div className="Add-Entries">
                    <Link to="/time-entry/add">
                      {" "}
                      <h4 className="btn btn-primary">
                        <i
                          className="fa-solid fa-plus"
                          style={{ marginRight: "5px" }}
                        ></i>
                        New Entry
                      </h4>
                    </Link>
                  </div>
                )}
              </Card.Header>
              <div className=" align-items-center d-flex card-header">
                <div className="col-md-3">
                  <div
                    className="dataTables_length d-flex align-items-center"
                    id="user-list-table_length"
                    style={{ gap: "2%" }}
                  >
                    <label>Select Month</label>
                    <select
                      name="user-list-table_length"
                      aria-controls="user-list-table"
                      className="form-select form-select-sm w-50"
                      onChange={(e) => HandleMonth(e)}
                      defaultValue={currentMonth}
                    >
                      <option value="" disabled>
                        select
                      </option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="dataTables_length d-flex align-items-center"
                    id="user-list-table_length"
                    style={{ gap: "2%" }}
                  >
                    <label>Select Year</label>
                    <select
                      name="user-list-table_length"
                      aria-controls="user-list-table"
                      className="form-select form-select-sm w-50"
                      onChange={(e) => HandleYear(e)}
                      defaultValue={currentYear}
                    >
                      <option value={`${moment().year() - 2}`}>
                        {moment().year() - 2}
                      </option>
                      <option value={`${moment().year() - 1}`}>
                        {moment().year() - 1}
                      </option>
                      <option value={`${moment().year()}`}>
                        {moment().year()}
                      </option>
                    </select>
                  </div>
                </div>
                {Permissions.includes("View Dropdown") && (
                  <div className="col-md-3">
                    <div
                      className="dataTables_length d-flex align-items-center"
                      id="user-list-table_length"
                      style={{ gap: "2%" }}
                    >
                      <label>Select Employee</label>
                      <select
                        name="user-list-table_length"
                        aria-controls="user-list-table"
                        className="form-select form-select-sm w-50"
                        onChange={(e) => setUser(e.target.value)}
                      >
                        {userList &&
                          userList.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.firstname + " " + item.last_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
              <Card.Body className="px-0">
                <div className="table-responsive">
                  <table
                    id="timeEntry-list-table"
                    className="table table-striped bordered sticky-header-table"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr>
                        <th className="position-sticky">Project/Task</th>
                        {dateValues.map((date, index) => (
                          <th key={index}>{date}</th>
                        ))}
                        <th>Total</th>
                      </tr>
                    </thead>
                    {loading ? (
                      <tbody>
                        <tr>
                          <td className="position-sticky">Project/Task</td>
                          <td colSpan={20} className="text-center">
                            <Spinner
                              animation="border"
                              variant="primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <>
                        <tbody className="text-capitalize">
                          {projectTasks.map((projectTask, index) => {
                            const { rows, rowTotal } =
                              renderTableRows(projectTask);

                            return (
                              <tr key={projectTask}>
                                <td>{projectTask}</td>
                                {rows}
                                <td className="text-success">
                                  {rowTotal?.toFixed(1)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td style={{ padding: "0.5rem 1.5rem" }}>Total</td>
                            {columnTotals.map((total, index) => {
                              const date = new Date(year, month - 1, index + 1);
                              const isSunday = allSunday.includes(index + 1);
                              const isOnLeave = allLeaves.includes(index + 1);
                              const isHoliday = allHoliday.includes(index + 1);
                              console.log( parseFloat(total?.toFixed(1)), " :::::::: parseFloat(total?.toFixed(1))" ,total)
                              const formattedTotal =
                                isSunday && isOnLeave
                                  ? "S"
                                  : isSunday && isHoliday
                                    ? "S"
                                    : isOnLeave && isHoliday
                                      ? "H"
                                      : isOnLeave
                                        ? "L"
                                        : isHoliday
                                          ? "H"
                                          : isSunday
                                            ? "S"
                                            : total !== 0
                                              ? parseFloat(total?.toFixed(1))
                                              : "";
                              const cellClass = `text-${formattedTotal === "H"
                                ? "warning"
                                : formattedTotal === "L"
                                  ? themeMode.scheme === "dark"
                                    ? "info"
                                    : "primary"
                                  : formattedTotal === "S"
                                    ? "danger"
                                    : "success"
                                }`;
                                console.log(formattedTotal)
                              return (
                                <td
                                  style={{
                                    padding: "0.5rem 1.5rem",
                                    textAlign: "center",
                                  }}
                                  className={cellClass}
                                  key={index}
                                >
                                  {formattedTotal == "0.0"
                                    ? ""
                                    : formattedTotal}
                                </td>
                              );
                            })}
                            <td
                              className="text-success"
                              style={{ padding: "0.5rem 1.5rem" }}
                            >
                              {columnTotals
                                ?.reduce((total, val) => total + val, 0)
                                ?.toFixed(1)}
                            </td>
                          </tr>
                        </tfoot>
                      </>
                    )}
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TimeEntryHistory;
