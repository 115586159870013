import React from 'react';
import { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import { showToast } from '../../plugins/toastUtils'

function Addtechnology({ api }) {

    const [technology, setTechnology] = useState('');
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const Addtechnology = (e) => {
        e.preventDefault();
        setLoading(true);
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        if (isValid) {
            api.post(`/technology`, {
                technology,
            }).then((response) => {
                if (response.status === false) {
                    navigate('/403');
                } else {
                    navigate('/technology');
                    setTimeout(() => {
                        showToast(response.data.message, "success")
                    }, 300);
                }

            }).catch((error) => {
                if (error.response.status == 403) {
                    navigate('/403')
                }
                showToast(error.response.data.errors, 'error');
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setValidated(true);
            setLoading(false);
        }
    };

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="6">
                        <Card>

                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Add Technology</h4>
                                </div>
                            </Card.Header>

                            <Card.Body>

                                <Form
                                    id="form-wizard1"
                                    onSubmit={(e) => Addtechnology(e)}
                                    noValidate
                                    validated={validated}
                                >

                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="role">Technology Name :-</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="technology"
                                            onChange={(e) => setTechnology(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            This field is required
                                        </Form.Control.Feedback>

                                    </Form.Group>

                                    <Button
                                        type="submit"
                                        variant="btn btn-primary me-2"
                                        disabled={loading}
                                    >
                                        {loading ? 'Submitting...' : 'Submit'}
                                    </Button>

                                    <Button
                                        type="button"
                                        variant="btn btn-danger"
                                        onClick={() => navigate('/technology')}
                                    >
                                        Cancel
                                    </Button>

                                </Form>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Addtechnology;