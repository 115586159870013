import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import ToastContainerWrapper from '../../plugins/ToastContainerWrapper'
import { showToast, successShowToast } from '../../plugins/toastUtils'

function AddLeave({ api }) {

    const userdata = useSelector((state) => state.user);
    const [reason, setReason] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [next_date, setNext_date] = useState('');
    const [prev_date, setPrev_date] = useState('');
    const [halfDay, setHalfDay] = useState('');
    const [totalLeaveDay, setTotalLeaveDay] = useState('');
    const [validated, setValidated] = useState(false);
    const [adHockStatus, setAdHockStatus] = useState("0");
    const [holidays, setHolidays] = useState([]);
    const [existDateFromError, setExistDateFromError] = useState(false);
    const [holidayError, setHolidayError] = useState(false);
    const [existDates, setExistDates] = useState([]);
    const [existDateToError, setExistDateToError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [matchingDates, setMatchingDates] = useState([]);
    const [isFromSunday, setFromSunday] = useState(false);
    const [isFromSaturday, setFromSaturday] = useState(false);
    const [isToSunday, setToSunday] = useState(false);
    const [isToSaturday, setToSaturday] = useState(false);
    const [paidLeaveAlert, setPaidLeaveAlert] = useState(false);
    const [userLeavesData, setUserLeavesData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        api.get(`/addLeaves`).then((response) => {
            const holidayDates = response.data.allHolidayDate;
            const hhData = []
            for (let i = 0; i < holidayDates.length; i++) {
                const momentDate = moment(holidayDates[i]);
                const formattedDate = momentDate.format('DD-MM-YYYY');
                hhData.push(formattedDate);
            }
            setHolidays(hhData);

            let existdate = response.data.existLeaveDates;
            const dates = [];
            existdate.forEach(item => {
                const start = moment(item.datefrom);
                const end = moment(item.dateto);
                while (start <= end) {
                    dates.push(start.format('YYYY-MM-DD'));
                    start.add(1, 'day');
                }
            });
            const uniqueDates = [...new Set(dates)];
            setExistDates(uniqueDates);
        }).catch((error) => {
            if (error.response.status == 403) {
                navigate('/403')
            }
            showToast(error.response.data.errors, 'error');
        })
        api.get(`/leaveData/${userdata.user._id}`)
            .then(res => setUserLeavesData(res.data.leaveHistoryData))
            .catch(() => setUserLeavesData({}))
        return () => {
        };
    }, []);

    const LeaveRequest = async (e) => {
        e.preventDefault();
        setLoading(true);
        const form = e.currentTarget;
        const isValid = form.checkValidity();
        if (isValid && !existDateToError && !existDateFromError && !isFromSaturday && !isToSaturday && !isFromSunday && !isToSunday && !paidLeaveAlert) {
            await api.post(`/addLeaves`, {
                user_id: userdata.user._id,
                reason: reason,
                datefrom: dateFrom,
                dateto: dateTo,
                is_adhoc: adHockStatus,
                half_day: halfDay,
                totalLeaveDay: totalLeaveDay
            }).then((response) => {
                navigate('/leaves');
                setTimeout(() => {
                    showToast(response.data.message, "success")
                }, 300);
            }).catch((error) => {
                console.log("error", error);
                // if(error.response.status == 403){
                //     navigate('/403')
                //  }
                // setLoading(false);
                // showToast(error.response.data.errors, 'error');
            })
        } else {
            setValidated(true);
            setLoading(false);
        }

    }

    const HandleAdHocLeaves = (e) => {
        const { checked } = e.target;
        if (checked) {
            setAdHockStatus('1')
            setExistDateFromError(false);
            setExistDateToError(false)
        } else {
            setExistDateFromError(false);
            setExistDateToError(false)
            setAdHockStatus('0')
        }
    }

    const HandleHalfDay = (e) => {
        setHalfDay(e.target.value)
    }

    const calculateTotalLeaveDays = () => {
        if (dateFrom || dateTo) {
            const start = moment(dateFrom, 'YYYY-MM-DD');
            const end = moment(dateTo, 'YYYY-MM-DD');
            let totalLeaveDays = 0;

            for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days')) {
                const dayOfWeek = m.format('dddd');
                const currentDate = m.format('DD-MM-YYYY');
                if (dayOfWeek !== 'Sunday' && dayOfWeek !== 'Saturday' && !holidays.includes(currentDate)) {
                    totalLeaveDays++;
                }
            }
            if (userLeavesData?.remaining_leaves !== 0 && totalLeaveDays > userLeavesData?.remaining_leaves) {
                setPaidLeaveAlert(true)
            } else {
                setPaidLeaveAlert(false)
            }
            if (halfDay === '') {
                setTotalLeaveDay(totalLeaveDays);
            } else {
                setTotalLeaveDay(0.5)
            }
        }
    };

    useEffect(() => {
        if (dateFrom !== '') {
            const startDate = new Date(dateFrom);
            startDate.setDate(startDate.getDate());
            const nextDate = startDate.toISOString().substr(0, 10);
            setNext_date(nextDate);
        }
        if (dateTo !== '') {
            const endDate = new Date(dateTo);
            endDate.setDate(endDate.getDate());
            const prevDate = endDate.toISOString().substr(0, 10);
            setPrev_date(prevDate);
        }
        calculateTotalLeaveDays();
        return () => {
        };
    }, [dateFrom, dateTo, halfDay]);

    useEffect(() => {
        let dateFromStr = new Date(dateFrom).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-');
        let dateToStr = new Date(dateTo).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-');
        if (dateFrom) {
            if (existDates.includes(dateFrom)) {
                setExistDateFromError(true)
            }
            else {
                setExistDateFromError(false)
            }
        }
        if (dateFrom || dateTo) {

            if (holidays.includes(dateFromStr) || holidays.includes(dateToStr)) {
                setHolidayError(true)
            }
            else {
                setHolidayError(false)
            }
        }
        if (dateFrom && dateTo) {
            const start = dateFrom;
            const end = dateTo;
            const dates = [];
            let curr = moment(start).startOf('day');
            while (curr.add(1, 'days').diff(end) < 0) {
                dates.push(new Date(curr.clone().toDate()).toISOString().split('T')[0]);
            }
            const formattedDates = [dateFrom, ...dates.map(date => date), dateTo];
            const matchingDates = formattedDates.filter(date =>
                existDates.includes(date)
            );
            if (matchingDates.length > 0) {
                setExistDateToError(true);
            }
            else {
                setExistDateToError(false);
            }
            setMatchingDates(matchingDates);
        }

        if ((new Date(dateFrom).getDay() === 0) === true) {
            setFromSunday(true);
            setFromSaturday(false);
        } else if ((new Date(dateFrom).getDay() === 6) === true) {
            setFromSunday(false);
            setFromSaturday(true);
        }
        else {
            setFromSunday(false);
            setFromSaturday(false);
        }
        if ((new Date(dateTo).getDay() === 0) === true) {
            setToSunday(true);
            setToSaturday(false);
        } else if ((new Date(dateTo).getDay() === 6) === true) {
            setToSunday(false);
            setToSaturday(true);
        }
        else {
            setToSunday(false);
            setToSaturday(false);
        }
        return () => {
        };
    }, [dateFrom, dateTo]);

    useEffect(() => {
        if (adHockStatus) {
            setDateFrom('')
            document.getElementById('From').value = '';
            document.getElementById('To').value = '';
        }
        if (halfDay) {
            document.getElementById('From').value = '';
            document.getElementById('To').value = '';
        }
        if (existDates.includes(dateFrom)) {
            setExistDateFromError(true)
            setExistDateToError(false)
        } else {
            setExistDateFromError(false)
        }
    }, [adHockStatus, halfDay]);

    useEffect(() => {
        if (halfDay !== '') {
            setTotalLeaveDay(0.5);
            if (existDates.includes(dateFrom)) {
                setExistDateFromError(true)
                setExistDateToError(false)
            } else {
                setExistDateFromError(false)
                setDateTo(dateFrom);
            }
        } else {
            setExistDateToError(false)
            setExistDateToError(false)
        }

        return () => {

        };
    }, [halfDay, dateFrom]);

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">New Leave Request</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={e => LeaveRequest(e)} noValidate validated={validated}>
                                    <Row>
                                        <Col sm="12" lg="4" className='d-flex align-items-end'>
                                            <Form.Group className="form-group" style={{ width: "100%" }}>
                                                <div className="bd-example">
                                                    <ListGroup>
                                                        <ListGroupItem as="label" style={{ paddingRight: '15rem' }}>
                                                            <input className="form-check-input me-1" type="checkbox" defaultValue="" onChange={(e) => HandleAdHocLeaves(e)} />
                                                            Ad-Hoc Levaes
                                                        </ListGroupItem>
                                                    </ListGroup>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="4">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Reason</Form.Label>
                                                <Form.Control type="text" id="title" onChange={e => setReason(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="4">
                                            <Form.Label htmlFor="permission_description">Select Leave Type:</Form.Label>
                                            <select
                                                name=""
                                                id=""
                                                className="form-select mb-3 shadow-none form-control"
                                                onChange={(e) => HandleHalfDay(e)}
                                            >
                                                <option value="">Full Leave</option>
                                                <option value="firsthalf">First Half (AM)</option>
                                                <option value="secondhalf">Second Half (PM)</option>
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" lg="4">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">From</Form.Label>
                                                <Form.Control type="date" id="From" onChange={e => setDateFrom(e.target.value)}
                                                    min={
                                                        adHockStatus === "0" ? new Date(new Date().setDate(new Date().getDate())).toISOString().split("T")[0]
                                                            :
                                                            "1900-01-01"}
                                                    max={adHockStatus === "1" && halfDay !== '' ? new Date(new Date().setDate(new Date().getDate())).toISOString().split("T")[0] : adHockStatus === "1" ? new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0] : prev_date
                                                    }
                                                    style={existDateFromError || isFromSaturday || isFromSunday ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                />
                                                {existDateFromError &&
                                                    <Form.Control.Feedback type="invalid" className='d-block'>
                                                        You have already taken leave of {moment(dateFrom).format('D MMMM')}
                                                    </Form.Control.Feedback>
                                                }
                                                {holidayError &&
                                                    < Form.Control.Feedback type="invalid" className='d-block'>
                                                        selected date is holiday
                                                    </Form.Control.Feedback>
                                                }
                                                {isFromSaturday &&
                                                    <Form.Control.Feedback type="invalid" className='d-block'>
                                                        Selected date is Saturday.
                                                    </Form.Control.Feedback>
                                                }
                                                {isFromSunday &&
                                                    <Form.Control.Feedback type="invalid" className='d-block'>
                                                        Selected date is Sunday.
                                                    </Form.Control.Feedback>
                                                }
                                            </Form.Group>
                                        </Col>
                                        {halfDay !== '' ?
                                            <Col sm="12" lg="4">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="permission_description">To</Form.Label>
                                                    <Form.Control type="date" id="To"
                                                        onChange={e => setDateTo(e.target.value)}
                                                        min={`${next_date}`}
                                                        max={adHockStatus === 1 ? new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0] : dateFrom}

                                                        disabled={dateFrom && !existDateFromError ? false : true}
                                                        style={existDateFromError || isToSaturday || isToSunday ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                        value={dateFrom}
                                                    />
                                                    {existDateToError && (
                                                        <Form.Control.Feedback type="invalid" className="d-block">
                                                            You have already taken leave of {matchingDates && matchingDates.map((item, index) => {
                                                                const formattedDate = moment(item).format("D MMMM");
                                                                return index === matchingDates.length - 1 ? formattedDate : formattedDate + ", ";
                                                            })}
                                                        </Form.Control.Feedback>
                                                    )}
                                                    {isToSaturday &&
                                                        <Form.Control.Feedback type="invalid" className='d-block'>
                                                            Selected date is Saturday.
                                                        </Form.Control.Feedback>
                                                    }
                                                    {isToSunday &&
                                                        <Form.Control.Feedback type="invalid" className='d-block'>
                                                            Selected date is Sunday.
                                                        </Form.Control.Feedback>
                                                    }
                                                </Form.Group>
                                            </Col>
                                            :
                                            <Col sm="12" lg="4">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="permission_description">To</Form.Label>
                                                    <Form.Control type="date" id="To" onChange={e => setDateTo(e.target.value)}
                                                        min={`${next_date}`}
                                                        max={adHockStatus === "1" ? new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0] : ''}
                                                        disabled={halfDay === '' && dateFrom && !existDateFromError ? false : true}
                                                        style={existDateFromError || isToSaturday || isToSunday ? { backgroundImage: 'none', borderColor: '#c03221' } : {}}
                                                    />
                                                    {isToSaturday &&
                                                        <Form.Control.Feedback type="invalid" className='d-block'>
                                                            Selected date is Saturday.
                                                        </Form.Control.Feedback>
                                                    }
                                                    {isToSunday &&
                                                        <Form.Control.Feedback type="invalid" className='d-block'>
                                                            Selected date is Sunday.
                                                        </Form.Control.Feedback>
                                                    }
                                                    {existDateToError && (
                                                        <Form.Control.Feedback type="invalid" className="d-block">
                                                            You have already taken leave of {matchingDates && matchingDates.map((item, index) => {
                                                                const formattedDate = moment(item).format("D MMMM");
                                                                return index === matchingDates.length - 1 ? formattedDate : formattedDate + ", ";
                                                            })}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        }
                                        <Col sm="12" lg="4">
                                            <Form.Group className="form-group position-relative">
                                                <Form.Label htmlFor="role">Total Leave Day:</Form.Label>
                                                <Form.Control type="text" id="title" value={totalLeaveDay} onChange={e => setTotalLeaveDay(e.target.value)} disabled />
                                                {paidLeaveAlert && <Col sm="12" lg="6" className='position-absolute text-danger fs-6 fw-bold w-100'>
                                                        You currently have {userLeavesData?.remaining_leaves} paid leaves available.
                                                </Col>}
                                            </Form.Group>
                                        </Col>
                                        <Col sm="12" lg="6">
                                            <Button type="submit" variant="btn btn-primary me-2" disabled={loading || paidLeaveAlert}> {loading ? 'Submiting...' : 'Submit'}</Button>
                                            <Button type="button" variant="btn btn-danger" onClick={() => navigate('/leaves')}>cancel</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <ToastContainerWrapper />
                </Row>
            </div >
        </>
    )
}

export default AddLeave

